import { useStableReducer } from '@/hooks/useStable';
import { Device, MetaPageType, PageType } from '@/types/app';
import { Component } from '@/types/component';
import { Dispatch, createContext } from 'react';

export interface AppState extends Record<string | number, any> {
  device: Device;
  type: PageType;
  metaPageType?: MetaPageType;
  category?: string;
  publishedUrl?: string;
  affiliate?: string;
  pageId?: number;
  isNativeAd?: boolean;
  isSecondNativeArticleLoaded?: boolean;
  accessLevel?: 'open' | 'login' | 'purchase';
  alltIdUserCode?: string;
  gamesUrls?: string[];
}

export type AppStateValue = [AppState, Dispatch<Partial<AppState>>];

export const AppStateContext = createContext<AppStateValue>([{} as AppState, () => {}]);

export const AppStateProvider: Component<AppState> = ({ children, ...initialPageState }) => {
  const value = useStableReducer((state: AppState, update: Partial<AppState>) => {
    return { ...state, ...update };
  }, initialPageState as AppState);

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>;
};
